import { handleActions, createAction } from 'redux-actions'
import pl from '../translations/pl.yml'
import de from '../translations/de.yml'
import en from '../translations/en.yml'

const initialState = {
  language: 'pl',
  translations: pl,
  availableLanguages: ['pl', 'de', 'en'],
}

export const CHANGE_TRANSLATIONS = createAction('CHANGE_TRANSLATIONS')

const TranslationsReducer = handleActions({
  [CHANGE_TRANSLATIONS]: (state, { payload, }) => {
    switch (payload) {
      case 'PL':
        return { ...state, language: 'pl', translations: pl, }
      case 'DE':
        return { ...state, language: 'de', translations: de, }
      case 'EN':
        return { ...state, language: 'en', translations: en, }
      default:
        return state
    }
  },
}, initialState)

export default TranslationsReducer
