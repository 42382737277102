// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-area-page-template-js": () => import("./../../../src/templates/areaPageTemplate.js" /* webpackChunkName: "component---src-templates-area-page-template-js" */),
  "component---src-templates-area-sub-page-template-js": () => import("./../../../src/templates/areaSubPageTemplate.js" /* webpackChunkName: "component---src-templates-area-sub-page-template-js" */),
  "component---src-templates-contact-page-template-js": () => import("./../../../src/templates/contactPageTemplate.js" /* webpackChunkName: "component---src-templates-contact-page-template-js" */),
  "component---src-templates-green-school-page-template-js": () => import("./../../../src/templates/greenSchoolPageTemplate.js" /* webpackChunkName: "component---src-templates-green-school-page-template-js" */),
  "component---src-templates-index-page-template-js": () => import("./../../../src/templates/indexPageTemplate.js" /* webpackChunkName: "component---src-templates-index-page-template-js" */),
  "component---src-templates-offer-page-template-js": () => import("./../../../src/templates/offerPageTemplate.js" /* webpackChunkName: "component---src-templates-offer-page-template-js" */),
  "component---src-templates-policy-page-template-js": () => import("./../../../src/templates/policyPageTemplate.js" /* webpackChunkName: "component---src-templates-policy-page-template-js" */),
  "component---src-templates-terms-page-template-js": () => import("./../../../src/templates/termsPageTemplate.js" /* webpackChunkName: "component---src-templates-terms-page-template-js" */),
  "component---src-templates-winter-holidays-page-template-js": () => import("./../../../src/templates/winterHolidaysPageTemplate.js" /* webpackChunkName: "component---src-templates-winter-holidays-page-template-js" */)
}

