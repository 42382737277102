import { handleActions, createAction } from 'redux-actions'
export const TOGGLE_CHOSE_LANG_MODAL = createAction('TOGGLE_CHOSE_LANG_MODAL')

const initialState = {
  choseLanguageModalOpen: false,
}

const AppReducer = handleActions({
  [TOGGLE_CHOSE_LANG_MODAL]: (state) => {
    return { ...state, choseLanguageModalOpen: !state.choseLanguageModalOpen, }
  },
}, initialState)

export default AppReducer
